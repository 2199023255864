<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useForm } from 'vee-validate'
import { watch } from 'vue'
import { z } from 'zod'

import AuthExistingUserLogin from '~/components/auth/ExistingUserLogin.vue'
import AuthRecoverPasssword from '~/components/auth/RecoverPassword.vue'
import Modal from '~/components/shared/modal.vue'
import { Base400Gray400, Sm400Gray, Sm600Orange } from '~/components/typography'
import {
  BtnFacebook,
  BtnGoogle,
  BtnOrange,
  BtnSoundcloud,
} from '~/components/ui/Buttons'
import VTextInput from '~/components/ui/Inputs/VText.vue'
import VText from '~/components/ui/VText.vue'

import { useAddAccountToFamily } from '~/composables/Auth/useAddAccountToFamily'
import { useLogin } from '~/composables/Auth/useLogin'
import { useStartSso } from '~/composables/Auth/useStartSso'
import { useSegmentIdentify } from '~/composables/Segment/useSegmentIdentify'
import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'
import { useFieldValidation } from '~/composables/useFieldValidation'
import { useGetMediaUrl } from '~/composables/useGetMediaUrl'
import { useLanguage } from '~/composables/useLanguage'

import { useBandSignupStore } from '~/stores/bandSignup'
import { useLoginStore } from '~/stores/login'
import { useRootStore } from '~/stores/root'
import { useSnackbarStore } from '~/stores/snackbar'
import { useUserStore } from '~/stores/user'
import { useUserBandStore } from '~/stores/userBand'

import {
  isFeatureOnForGroup,
  setGrowthBookAttributes,
} from '~/helpers/GrowthBookHelpers'
import { trackEvent } from '~/helpers/LegacyTrackEvent'
import { backupAccountSecret } from '~/helpers/SwapAccount'

import { getNextQueryFromWindowLocationSearch } from '~/utils/getNextQueryFromWindowLocationSearch'

import { provideAuthApiSsoLogin } from '~/api-auth/Login'
import { provideAuthApiLogout } from '~/api-auth/Logout'
import {
  provideLoginSession,
  provideLogoutSession,
} from '~/api-core/Login/Session'

import type { LoginUser } from '~/composables/Auth/useLogin'
import type { SsoProvider } from '~/types/Auth'

type Props = {
  modelValue: boolean
}

type Emits = {
  'update:modelValue': [newModelValue: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const { updateLanguageToCurrentState } = useLanguage()
const { getMediaUrl } = useGetMediaUrl()
const { $growthBook } = useNuxtApp()
const addAccountToFamily = useAddAccountToFamily()
const loginWithEmailPassword = useLogin()
const localePath = useLocalePath()
const segmentIdentify = useSegmentIdentify()
const segmentTrack = useSegmentTrack()
const { localeCodes, t } = useI18n()
const route = useRoute()
const { authStartSso } = useStartSso()
const { path } = route

const { validate: formValidate, resetForm: resetFormValidation } = useForm<{
  email: string
  password: string
}>()

const { value: emailFieldValue, errorMessage: emailErrorMessage } =
  useFieldValidation<string>(
    'email',
    z
      .string()
      .transform((value) => value.trim())
      .pipe(z.string().email(t('auth.login.error.emailInvalid'))),
    {
      syncVModel: false,
      validateOnValueUpdate: false,
      initialValue: '',
    },
  )
const { value: passwordFieldValue, errorMessage: passwordErrorMessage } =
  useFieldValidation<string>(
    'password',
    z.string().min(8, t('auth.login.error.passwordMinLength')),
    {
      syncVModel: false,
      validateOnValueUpdate: false,
      initialValue: '',
    },
  )

const errorUser = ref('')
const loading = ref(false)
const displayPassword = ref(false)
const displayPasswordAnnotation = ref(false)
const googleAccountDeleted = ref(false)

const loginStore = useLoginStore()
const {
  existingUser: LOGIN_EXISTING_USER,
  display: LOGIN_IS_DISPLAYED,
  swapMode: LOGIN_IS_IN_SWAP_MODE,
  displayPasswordRecovery: LOGIN_DISPLAY_PASSWORD_RECOVERY,
  EXISTING_USER_IS_VALID,
} = storeToRefs(loginStore)
const { id: USER_ID, IS_LOGGED_IN } = storeToRefs(useUserStore())
const { id: USER_SELECTED_BAND_ID } = storeToRefs(useUserBandStore())

const {
  RESET_EXISTING_USER: LOGIN_RESET_EXISTING_USER,
  SET_DISPLAY_PASSWORD_RECOVERY: LOGIN_SET_DISPLAY_PASSWORD_RECOVERY,
} = loginStore
const { USER_LOAD: INDEX_USER_LOAD } = useRootStore()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()
const { CREATE: CREATE_SNACKBAR } = useSnackbarStore()

const passwordInputType = computed<'text' | 'password'>(() =>
  displayPassword.value ? 'text' : 'password',
)
// Start AB Test 'soundcloud-auth-rollout'
const isSoundCloudAuthEnabled = computed<boolean>(() =>
  isFeatureOnForGroup(
    $growthBook,
    'soundcloud-auth-rollout',
    'soundcloud-auth-enabled',
  ),
)
// End AB Test 'soundcloud-auth-rollout'

watch(
  () => props.modelValue,
  (value) => {
    if (!value) {
      LOGIN_SET_DISPLAY_PASSWORD_RECOVERY(false)
      LOGIN_RESET_EXISTING_USER()
      trackEvent(
        {
          category: 'Login',
          action: 'Close',
        },
        route,
      )
    } else {
      trackEvent(
        {
          category: 'Login',
          action: 'Open',
        },
        route,
      )
    }
  },
)

function handleRequestPasswordChangeClick() {
  LOGIN_SET_DISPLAY_PASSWORD_RECOVERY(true)
  trackEvent(
    {
      category: 'Login',
      action: 'Request password change',
    },
    route,
  )
}

function trackLoginEvent(
  action: 'Attempt' | 'Success' | 'Failure',
  type: 'facebook' | 'email' | 'google' | 'soundcloud',
): void {
  try {
    // Legacy flow this event doesn't make sense any more
    // Just leave as is till we get rid of all legacy envents
    trackEvent(
      {
        category: 'Login',
        action,
        isSwapMode: false,
        isRecoveringExisting: false,
        type,
      },
      route,
    )
    if (action !== 'Success') return

    const pageTitleRegex = new RegExp(localeCodes.value.join('|'))
    const pageTitle = pageTitleRegex.test(path) ? 'landing-page' : 'seo-page'
    segmentTrack('Logged In - Logged In Finalized', {
      page_title: pageTitle,
    })
  } catch (_) {}
}

function handleModalCancel(): void {
  if (LOGIN_DISPLAY_PASSWORD_RECOVERY.value)
    LOGIN_SET_DISPLAY_PASSWORD_RECOVERY(false)
  else LOGIN_RESET_EXISTING_USER()

  resetValidation()
}

async function handleLogin(): Promise<void> {
  if (loading.value) return

  const { valid } = await formValidate()

  if (!valid) return

  loading.value = true
  trackLoginEvent('Attempt', 'email')
  login().finally(() => (loading.value = false))
}

function resetValidation() {
  resetFormValidation()
  errorUser.value = ''
}

async function login(): Promise<void> {
  try {
    const response = await loginWithEmailPassword(
      {
        email: emailFieldValue.value.toLowerCase().trim(),
        password: passwordFieldValue.value,
      },
      LOGIN_IS_IN_SWAP_MODE.value,
    )
    trackLoginEvent('Success', 'email')
    segmentIdentify({ user_id: response.id, sign_up_method: 'email' })
    setGrowthBookAttributes({ userId: response.id }, $growthBook)
    successfulLoginHandler(response)
  } catch (_) {
    errHandle('mismatch')
  }
}

async function loginWithSso(
  provider: SsoProvider,
  code: string,
): Promise<void> {
  const linkUsers = LOGIN_IS_IN_SWAP_MODE.value
  const loginWithSso = provideAuthApiSsoLogin($authFetch)
  const loginToCore = provideLoginSession($coreFetch)
  const logoutFromCore = provideLogoutSession($coreFetch)

  trackLoginEvent('Attempt', provider)
  try {
    const { access } = await loginWithSso(provider, code)
    if (linkUsers) await addAccountToFamily(access)

    await logoutFromCore()
    const response = await loginToCore(access)

    trackLoginEvent('Success', provider)
    segmentIdentify({
      user_id: response.id,
      sign_up_method: 'facebook',
    })
    setGrowthBookAttributes({ userId: response.id }, $growthBook)
    if (linkUsers) backupAccountSecret(response.id, access)

    successfulLoginHandler(response)
  } catch (error) {
    // No associated account!
    errHandle('noAccount')
  }
}

function loginWithFacebook(): void {
  trackLoginEvent('Attempt', 'facebook')
  authStartSso('facebook', (code) => {
    loginWithSso('facebook', code)
  })
}

async function v2LoginWithGoogle() {
  await logoutFromAuth()

  authStartSso('google', (code) => {
    loginWithSso('google', code)
  })
}

async function v2LoginWithSoundcloud(): Promise<void> {
  await logoutFromAuth()

  authStartSso('soundcloud', (code) => {
    loginWithSso('soundcloud', code)
  })
}

async function logoutFromAuth() {
  if (USER_ID.value === 0) return

  const logoutFromAuth = provideAuthApiLogout($authFetch)
  await logoutFromAuth()
}

async function successfulLoginHandler(data: LoginUser) {
  // If in swap mode force full reload.
  if (LOGIN_IS_IN_SWAP_MODE.value)
    return (window.location.href = window.location.origin + '/')

  loading.value = false
  await INDEX_USER_LOAD()
  await nextTick()

  // Make sure app locale is up to date with logged in user
  await updateLanguageToCurrentState()
  /**
   * Can contain any url
   */
  const nextQuery = getNextQueryFromWindowLocationSearch()

  emailFieldValue.value = ''
  passwordFieldValue.value = ''
  if (data.agency === null && data.influencer === null) {
    const bandSignupTimestamp = window.localStorage.getItem(
      'bandSignupTimestamp',
    )
    const influencerSignupTimestamp = window.localStorage.getItem(
      'influencerSignupTimestamp',
    )

    if (
      Number(influencerSignupTimestamp ?? 0) > Number(bandSignupTimestamp ?? 0)
    )
      await navigateTo(localePath(nextQuery || '/influencer/signup/'))
    else await navigateTo(localePath(nextQuery || '/band/signup/'))
  } else if (data.agency !== null && USER_SELECTED_BAND_ID.value === 0) {
    /**
     * In case of an agency, redirect to the profile select and pass along any next query
     */
    await navigateTo(
      localePath({
        path: '/band/profile/select/',
        query: {
          next: nextQuery,
        },
      }),
    )
  } else if (data.influencer) {
    await navigateTo(localePath(nextQuery || '/influencer/dashboard/'))
  } else if (data.agency) {
    await navigateTo(localePath(nextQuery || '/band/homepage/'))
  } else {
    throw new Error('failed to login')
  }

  resetValidation()
  emit('update:modelValue', false)
}

function errHandle(
  i18nTag?:
    | 'addAccountNoFacebook'
    | 'baseError'
    | 'facebookError'
    | 'facebookNoLogin'
    | 'noAccount'
    | 'mismatch',
) {
  loading.value = false
  if (i18nTag) errorUser.value = t(`auth.login.error.${i18nTag}`) as string

  if (EXISTING_USER_IS_VALID.value) {
    CREATE_SNACKBAR({
      props: {
        text: emailErrorMessage.value,
        icon: 'fas fa-bug',
      },
    })
  }

  trackLoginEvent(
    'Failure',
    i18nTag?.includes('facebook') ? 'facebook' : 'email',
  )
}

function requestSignup() {
  if (path.includes('/band/signup/referral/')) emit('update:modelValue', false)
  else BAND_SIGNUP_SET_DISPLAY(true)
  // Will close login if open
  trackEvent(
    {
      category: 'Navbar',
      action: 'Open sign up dialog',
      label: 'Sign in modal',
    },
    route,
  )
}
</script>

<template>
  <Modal
    :model-value="modelValue"
    :title="
      LOGIN_DISPLAY_PASSWORD_RECOVERY
        ? t('auth.passwordReset.title')
        : t('auth.login.title')
    "
    :has-cancel="LOGIN_DISPLAY_PASSWORD_RECOVERY || EXISTING_USER_IS_VALID"
    @close="emit('update:modelValue', false)"
    @update:model-value="emit('update:modelValue', $event)"
    @cancel="handleModalCancel"
  >
    <transition mode="out-in" name="fade">
      <AuthRecoverPasssword
        v-if="LOGIN_DISPLAY_PASSWORD_RECOVERY"
        key="password"
        @close="LOGIN_SET_DISPLAY_PASSWORD_RECOVERY(false)"
      />
      <AuthExistingUserLogin
        v-else-if="LOGIN_IS_IN_SWAP_MODE && EXISTING_USER_IS_VALID"
        key="existingUser"
        :user="LOGIN_EXISTING_USER"
      />
      <div
        v-else
        key="login"
        class="tw-mb-6 tw-grid tw-w-full tw-grid-cols-1 tw-gap-6"
      >
        <VText
          v-if="googleAccountDeleted"
          cfg="sans/16/medium"
          color="red-500"
          class="tw-block tw-text-center"
        >
          {{ t('errors.input.loginDisabledEmail') }}
        </VText>
        <div
          class="tw-mx-auto tw-grid tw-w-[272px] tw-grid-cols-1 tw-gap-3 sm:tw-w-[369px] md:tw-w-[400px]"
        >
          <BtnGoogle small @click="v2LoginWithGoogle">
            <div class="btnText tw-relative">
              <div
                class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-items-center"
              >
                <img
                  alt="google"
                  :src="getMediaUrl('googleLogo.svg')"
                  class="tw-h-4"
                />
              </div>
              <span>{{ t('auth.login.button.google') }}</span>
            </div>
          </BtnGoogle>
          <BtnFacebook small @click="loginWithFacebook">
            <div class="btnText tw-relative">
              <div
                class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-items-center"
              >
                <i class="fab fa-facebook-square tw-text-base tw-text-white" />
              </div>
              <span>{{ t('auth.login.button.facebook') }}</span>
            </div>
          </BtnFacebook>
          <ClientOnly>
            <!-- Start AB Test 'soundcloud-auth-rollout' -->
            <BtnSoundcloud
              v-if="isSoundCloudAuthEnabled"
              small
              @click="v2LoginWithSoundcloud"
            >
              <div class="btnText tw-relative">
                <div
                  class="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-items-center"
                >
                  <i class="fa-brands fa-soundcloud tw-text-white"></i>
                </div>
                <span>{{ t('auth.login.button.soundcloud') }}</span>
              </div>
            </BtnSoundcloud>
            <!-- End AB Test 'soundcloud-auth-rollout' -->
          </ClientOnly>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="line" />
          <Base400Gray400 class="orText">{{ t('common.or') }}</Base400Gray400>
          <div class="line" />
        </div>
        <div class="tw-grid tw-grid-cols-1 tw-gap-6">
          <VTextInput
            v-model="emailFieldValue"
            :p-validity="!emailErrorMessage && !errorUser"
            :label="t('auth.login.label.email')"
            :placeholder="t('auth.login.placeholder.email')"
            left-picto="fas fa-envelope"
            data-test-id="loginFormEmailInputField"
          >
            <!--Purposefully not using #err-message template for email -->
          </VTextInput>
          <div class="tw-relative">
            <VTextInput
              v-model="passwordFieldValue"
              :p-validity="!passwordErrorMessage && !errorUser"
              left-picto="fas fa-lock"
              :type="passwordInputType"
              :label="t('auth.login.label.password')"
              :placeholder="t('auth.login.placeholder.password')"
              hide-right-picto
              data-test-id="loginFormPasswordInputField"
              @focus="displayPasswordAnnotation = true"
              @enter="handleLogin"
            >
              <!--Purposefully only displaying the errorUser message here-->
              <template #err-message>{{ errorUser }}</template>
            </VTextInput>
            <transition name="scale" mode="out-in">
              <div
                :key="displayPassword.toString()"
                class="sightIcon"
                @click="displayPassword = !displayPassword"
              >
                <i
                  class="fas"
                  :class="{
                    'fa-eye-slash': displayPassword,
                    'fa-eye': !displayPassword,
                  }"
                />
              </div>
            </transition>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-start">
          <BtnOrange
            class="tw-mb-6 tw-block"
            data-test-id="loginFormSubmitCTA"
            :disabled="loading"
            @click="handleLogin"
            >{{ t('auth.login.title') }}</BtnOrange
          >
          <Sm600Orange
            class="tw-mb-6 tw-inline-block tw-cursor-pointer sm:tw-mb-4"
            data-test-id="loginFormForgotPasswordLink"
            @click="handleRequestPasswordChangeClick"
            >{{ t('auth.login.forgotPassword') }}</Sm600Orange
          >
          <div
            v-if="!IS_LOGGED_IN"
            class="tw-gap-x1 tw-grid tw-grid-cols-1 tw-text-center sm:tw-flex sm:tw-items-center sm:tw-justify-center"
          >
            <Sm400Gray class="tw-mb-1 tw-mr-1 tw-inline-block sm:tw-mb-0">{{
              t('auth.login.noAccount')
            }}</Sm400Gray>
            <Sm600Orange
              class="tw-block tw-cursor-pointer"
              data-test-id="loginFormSignupLink"
              @click="requestSignup"
              >{{ t('auth.login.signup') }}</Sm600Orange
            >
          </div>
        </div>
      </div>
    </transition>
  </Modal>
</template>
<style scoped lang="scss">
.line {
  @apply tw-h-px tw-w-full tw-bg-gray-300;
}

.orText {
  @apply tw-mx-4 tw-block tw-shrink-0 tw-uppercase;
}

.sightIcon {
  @apply tw-absolute tw-cursor-pointer tw-text-base tw-text-gray-500;

  top: 42px;
  right: 16px;
}

button.btn {
  @apply tw-w-full;
}
</style>
